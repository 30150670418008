.App {
  /* text-align: center; */
}
.container {
  display: inline-block;
  white-space: nowrap;
}

.checkbox {
  border: 1px solid transparent;
  text-align: right;
}

.checkbox input {
  float: left;
}

.new-customer-form {
  width: 500px;
  background-color: #f7f8f9;
  margin: 0 -20px;
  padding: 20px 30px 30px;
  border-radius: 4px;
  border: 1px solid #e8e8fb;
  flex-direction: column;
 position: relative;
 text-align: left;
}
section {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: left;
}

.logo{
  display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;

  }
  .titlehead{
    display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
  }
  .titleinstruc{
    display: inline-table;
   margin-top: -32px;
   padding: 0 5px;
   text-align: center;
   background: #f8fbfd;
  }
.new-customer-form .form-body {

  margin: 0 -30px;
 padding: 20px 30px 30px;
 border-radius: 10px;
 border: 1px solid #e8e8fb;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

fieldset {
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15),
    0 4px 6px 0 rgba(112, 157, 199, 0.15);
  border-radius: 4px;
  border: none;
  font-size: 0;
}

fieldset label {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 42px;
  padding: 10px 0;
  align-items: center;
  justify-content: center;
  color: #8898aa;
  font-weight: 400;
}

fieldset label:not(:last-child) {
  border-bottom: 1px solid #f0f5fa;
}

fieldset label.state {
  display: inline-flex;
  width: 75%;
}

fieldset:not(.with-state) label.state {
  display: none;
}

fieldset label.zip {
  display: inline-flex;
  width: 25%;
  padding-right: 60px;

}

fieldset:not(.with-state) label.zip {
  display: inline-flex;
  width: 50%;
  padding-right: 60px;
}

fieldset label span {
  min-width: 125px;
  padding: 0 15px;
  text-align: right;
}

fieldset .redirect label span {
  width: 100%;
  text-align: center;
}
.field {
  flex: 1;
  padding: 0 15px;
  background: transparent;
  font-weight: 400;
  color: #31325f;
  outline: none;
  cursor: text;
}

.field::-webkit-input-placeholder {
  color: #aab7c4;
}
.field::-moz-placeholder {
  color: #aab7c4;
}
.field:-ms-input-placeholder {
  color: #aab7c4;
}

fieldset .select::after {
  content: '';
  position: absolute;
  width: 9px;
  height: 5px;
  right: 20px;
  top: 50%;
  margin-top: -2px;
  background-image: url(/images/dropdown.svg);
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
input {
  flex: 1;
  border-style: none;
  outline: none;
  color: #313b3f;
}
select {
  flex: 1;
  border-style: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;

  cursor: pointer;
  background: transparent;
}

select:focus {

}

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
select,
input,
.StripeElement {
  flex: 1;
  border-style: none;
  outline: none;
  color: #313b3f;
}

::placeholder {
  font-family: "Helvetica Neue", "Helvetica", "sans-serif";
  font-size: 16px;
  font-weight: 500;
  color: silver;
  /* opacity: 1; */
}

:-ms-input-placeholder {
  font-family: "Helvetica Neue", "Helvetica", "sans-serif";
  font-size: 16px;
  font-weight: 500;
  color: silver;
}

::-ms-input-placeholder {
  font-family: "Helvetica Neue", "Helvetica", "sans-serif";
  font-size: 16px;
  font-weight: 500;
  color: silver;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

button {
  border: none;
  border-radius: 4px;
  outline: none;
  text-decoration: none;
  color: #fff;
  background: #32325d;
  white-space: nowrap;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.025em;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-left: 12px;
  margin-top: 28px;
}

.submit-btn {
  width: 100px;
  margin-top: 0px;
  cursor: pointer;
}

label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #6b7c93;
}

.form-row.inline .col:not(:last-child) {
  margin-right: 20px;
}

.form-row.inline .col {
  width: 100%;
}

.form-row.inline {
  display: flex;
}

.form-row:first-child {
  margin-top: 0;
}

.form-row {
  width: 100%;
  margin-top: 10px;
}

.card-errors,
#error-message {
  height: 20px;
  padding: 4px 0;
  color: #fa755a;
}

.form-header {
  margin: 15px 0;
    color: #32325d;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    font-size: 13px;
    font-weight: 500;
}

.text-align-center {
  text-align: center;
}
* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-size: 15px;
  line-height: 1.4em;
  color: #525f7f;
}
body {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f8fbfd;
}
#main {
  width: 100%;
  height: 100vh;
  text-align: center;
  transition: width 0.3s ease-in-out;
}
#main.checkout:not(.success):not(.error) {
  width: calc(100% - 450px);
}
